<template>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div :class="!cadastroRecorrencia ? 'tile is-parent is-vertical is-9' : 'tile is-parent is-vertical is-12'"  >
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-12">
                                    <ValidationProvider name="descrição" rules="required" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONTASPAGARCADASTRO.DESCRICAO')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model[index].descricao"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>                            
                            <div class="columns">
                                <div class="column is-6">
                                    <b-field :label="$t('CONTASPAGARCADASTRO.DATA')">
                                        <b-datetimepicker v-model="model[index].data"
                                                          :disabled="model[index].id > 0"
                                                          :placeholder="$t('CONTASPAGARCADASTRO.CATEGORIAFINANCEIRA')"
                                                          icon="calendar-today"
                                                          :timepicker="{ enableSeconds: true, hourFormat: '24' }"
                                                          horizontal-time-picker expanded>
                                        </b-datetimepicker>
                                    </b-field>
                                </div>                                
                                <div class="column is-6">

                                    <b-field :label="$t('CONTASPAGARCADASTRO.COMPETENCIA')">
                                            <b-datetimepicker v-model="model[index].dataDeCompetencia"
                                                            :disabled="model[index].id > 0"
                                                            icon="calendar-today"
                                                            :timepicker="{ enableSeconds: true, hourFormat: '24' }"
                                                            horizontal-time-picker expanded>
                                            </b-datetimepicker>
                                    </b-field>

                                </div>

                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <search-id-name :leitura="true" v-show="model[index].id != 0" :id.sync="model[index].funcionarioId" :label="$t('CONTASPAGARCADASTRO.FUNCIONARIO')" table="Funcionario"></search-id-name>                                                                
                                </div>
                                <div class="column is-6">
                                    <ValidationProvider name="categoria financeira" rules="required" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONTASPAGARCADASTRO.CATEGORIAFINANCEIRA')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select expanded :placeholder="$t('CONTASPAGARCADASTRO.CATEGORIAFINANCEIRA')" class="is-fullwidth" v-model="model[index].categoriaFinanceira.id">
                                                <option v-for="(item, x) in categoriasFinanceiras" v-bind:key="x" :value="item.id">{{item.registroNomes}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>                                    
                                </div>                                
                            </div>                            
                            <div class="columns">
                                <div class="column is-6">
                                    <b-field :label="$t('CONTASPAGARCADASTRO.NOTAFISCAL')">
                                        <b-input type="text" v-model="model[index].notaFiscal"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-6" v-if="config.naoVincularContaPagarContaCorrente == false">
                                    <ValidationProvider rules="required" name="conta corrente" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONTASPAGARCADASTRO.CONTACORRENTE')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select expanded :placeholder="$t('CONTASPAGARCADASTRO.PLACEHOLDERCONTACORRENTE')" class="is-fullwidth" v-model="model[index].contaCorrente.id" :readonly="model && model[index].id > 0">
                                                <option v-for="(item, index) in contasCorrentes" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                                
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <search-id-name :id.sync="model[index].unidadeId" :label="$t('CONTASPAGARCADASTRO.UNIDADE')" table="Unidade"></search-id-name>
                                </div>
                                <div class="column is-6">
                                    <ValidationProvider name="fornecedor" rules="required" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONTASPAGARCADASTRO.FORNECEDOR')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select expanded :placeholder="$t('CONTASPAGARCADASTRO.PLACEHOLDERFORNECEDOR')" class="is-fullwidth" v-model="model[index].fornecedor.id" :readonly="model && model[index].id > 0">
                                                <option v-for="(item, index) in fornecedores" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
     
                            
                            <div class="columns">
                                <div class="column is-12">
                                    <b-field :label="$t('CONTASPAGARCADASTRO.OBSERVACAO')">
                                        <b-input maxlength="5000" type="textarea" v-model="model[index].observacoes"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div v-if="!cadastroRecorrencia" class="tile is-parent">
                        <article class="tile is-child">   
                            <historico-alteracoes :tabela="'Pagar'" :chave="model[index].id"></historico-alteracoes>

                            <article class="panel">
                                <div class="panel-block">
                                    <b-button class="btn-action is-danger center" icon-left="close-circle" v-if="(model[index].id > 0 && model[index].cancelada==false && isInRole('pagar-cancelaFaturamento'))"
                                    @click.prevent.stop="modalCancelarFatura()" expanded>{{$t('CONTASPAGARCADASTRO.CANCELAR')}}</b-button>                                                        
                                </div>
                            </article>

                        </article>


                    </div>
                </div>
                <div class="tile is-ancestor">
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <div class="columns">
                                <article class="panel column">
                                    <div class="panel-heading has-text-centered">
                                        {{$t('CONTASPAGARCADASTRO.CENTRORESULTADOS')}}
                                    </div>
                                    <div class="panel-block">
                                        <table class="table is-bordered is-striped is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th width="10%"></th>
                                                    <th width="10%">{{$t('CONTASPAGARCADASTRO.ID')}}</th>
                                                    <th width="40%">{{$t('CONTASPAGARCADASTRO.CENTRORESULTADOS')}}</th>
                                                    <th width="20%">{{$t('CONTASPAGARCADASTRO.VALOR')}}</th>
                                                    <th width="20%">{{$t('CONTASPAGARCADASTRO.PORCENTAGEM')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, j) in model[index].centrosResultados?.filter(i => i.show===true)" :key="j">
                                                    <td>
                                                        <b-button
                                                            type="is-danger"
                                                            native-type="button"
                                                            icon-left="delete"
                                                            class="button-delete"
                                                            @click ="deleteCentroResultado(item,j)" >
                                                        </b-button>                                            
                                                    </td>
                                                    <td>{{item.id}}</td>
                                                    <td> 
                                                        <b-select v-model="item.centroResultados.id" :key="j">
                                                            <option v-for="(option, j) in centrosResultadosCadastro"
                                                                    :value="option.id"
                                                                    :key="j">
                                                                {{ option.registroNomes }}
                                                            </option>
                                                        </b-select>                                                    
                                                    </td>
                                                    <td> 
                                                        <b-input @input="alterouValor(item)" step=".00000001" type="number" v-model="item.valor"> </b-input>
                                                    </td>    
                                                    <td> 
                                                        <b-input required @input="alterouPorcentagem(item)" step=".00000001" type="number" v-model="item.porcentagem" icon-right="percent"></b-input> 
                                                    </td>                                           
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="panel-block">
                                        <table class="table is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <td width="10%"></td>
                                                    <td width="10%"></td>
                                                    <td width="40%"></td>
                                                    <td width="20%"></td>
                                                    <td width="20%"><b>Total: {{ totalCentroResultados | currency }}</b></td>
                                                </tr>                                                
                                            </thead>
                                        </table>
                                    </div>
                                    <div class="box">
                                        <article class="level">
                                            <div class="level-item">
                                                <button type="button" class="button is-info is-fullwidth" @click="addCentroResultado()">
                                                    {{$t('CONTASPAGARCADASTRO.ADICIONARCENTRORESULTADOS')}}
                                                </button>
                                            </div>
                                        </article>
                                    </div>
                                </article>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="tile is-ancestor">
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <div class="columns">
                                <article class="panel column">
                                    <div class="panel-heading has-text-centered">
                                        {{$t('CONTASPAGARCADASTRO.PARCELAS')}}
                                    </div>
                                    <div class="panel-block" v-for="(parcela, i) in model[index].parcelas" v-bind:key="parcela.id">
                                        <table class="table is-bordered is-striped is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th width="10%">{{$t('CONTASPAGARCADASTRO.VENCIMENTO')}}</th>                                                    
                                                    <th width="10%">{{$t('CONTASPAGARCADASTRO.VALORPARCIAL')}}</th>
                                                    <th width="10%">{{$t('CONTASPAGARCADASTRO.JUROS')}}</th>
                                                    <th width="10%">{{$t('CONTASPAGARCADASTRO.MULTA')}}</th>                                                    
                                                    <th width="20%">{{$t('CONTASPAGARCADASTRO.TOTAL')}}</th>
                                                    <th width="20%">{{$t('CONTASPAGARCADASTRO.SITUACAO')}}</th>
                                                    <th width="20%" v-if="parcela.situacao == 'Pago'">{{$t('CONTASPAGARCADASTRO.PAGOEM')}}</th>
                                                    <th width="20%" v-if="parcela.situacao == 'Pendente'" >{{$t('CONTASPAGARCADASTRO.ESPECIE')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <b-datepicker editable v-model="parcela.dataDeVencimento" trap-focus></b-datepicker>
                                                    </td>
                                                    <td>
                                                        <b-input type="number" step=".00000001" v-model="parcela.valorParcial"   @input="recalcularParcelas()"></b-input>
                                                    </td>                                                    
                                                    <td>
                                                        <b-input type="number" step=".00000001" v-model="parcela.juros"   @input="recalcularParcelas()"></b-input>
                                                    </td>
                                                    <td>
                                                        <b-input type="number" step=".00000001" v-model="parcela.multa" @input="recalcularParcelas()"></b-input>
                                                    </td>
                                                    <td>
                                                        <b-input type="number" step=".00000001" v-model="parcela.valor"  readonly></b-input>
                                                    </td>                                                      
                                                    <td>
                                                        <div v-if="parcela.situacao == 'Pendente'" class="btn-group btn-group-xs" role="group">
                                                            <button type="button" class="button is-warning">{{parcela.situacao}}</button>
                                                            <button type="button" class="button is-success" :title="$t('CONTASPAGARCADASTRO.DEFINIRCOMORECEBIDO')"
                                                                    @click="pagarParcela(i);">
                                                                <i class="mdi mdi-check"></i>
                                                            </button>
                                                            <button v-if="isInRole('pagar-cancelar-parcela')" type="button" class="button is-danger"
                                                                    :title="$t('CONTASPAGARCADASTRO.CLIQUEPARACANCELAR')" @click="cancelarParcela(i);">
                                                                <i class="mdi mdi-close-circle"></i>
                                                            </button>
                                                        </div>
                                                        <table v-else-if="parcela.situacao == 'Pago'" width="100%">
                                                            <tr>
                                                                <td>
                                                                    <button type="button" class="button is-success btn-xs">{{$t('CONTASPAGARCADASTRO.PAGO')}}</button>
                                                                </td>
                                                                <td style="font-size: 0.8em;" width="80%">
                                                                    <span v-if="parcela.dataDePagamento != null">
                                                                        em <b>{{$moment(parcela.dataDePagamento).format("DD/MM/YYYY HH:mm")}}</b>
                                                                    </span>
                                                                    por <b>{{parcela.funcionarioPagamento.nome}}</b>
                                                                    <br />
                                                                    Espécie: <b>{{parcela.especieDePagamento.nome}}</b>
                                                                    <span v-if="parcela.contaCorrente != null && parcela.contaCorrente.id != null">
                                                                        C/C: <b>{{parcela.contaCorrente.nome}}</b>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table v-else-if="parcela.situacao == 'Cancelado'" width="20%">
                                                            <tr>
                                                                <td>
                                                                    <button type="button" class="button is-danger btn-xs">{{$t('CONTASPAGARCADASTRO.CANCELADO')}}</button>
                                                                </td>
                                                                <td v-if="parcela.motivoCancelamento != null" style="font-size: 0.8em;" width="80%">
                                                                    <span>
                                                                        {{$t('CONTASPAGARCADASTRO.MOTIVOCANCELAMENTO')}}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        <b-datetimepicker 
                                                            v-if="parcela.situacao == 'Pago'" 
                                                            v-model="parcela.dataDePagamento" 
                                                            icon="calendar-today"
                                                            trap-focus>
                                                        </b-datetimepicker>                                                    
                                                        <div v-if="parcela.situacao == 'Pendente'">
                                                            <ValidationProvider rules="required"  v-slot="{ errors, valid }">
                                                                <b-field
                                                                        :message="errors"
                                                                        :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                                                    <b-select v-if="parcela.situacao == 'Pendente'" v-model="parcela.especieDePagamento">
                                                                        <!-- <option value=""></option> -->
                                                                        <option v-for="option in especies"
                                                                                :value="option"
                                                                                :key="option.id">
                                                                            {{ option.nome }}
                                                                        </option>
                                                                    </b-select>
                                                                </b-field>
                                                            </ValidationProvider>
                                                        </div>
                                                    </td> 
                                                </tr>
                                                <tr>
                                                    <template>
                                                        <td v-if="parcela.especieDePagamento.nome == 'TransferenciaBancaria'">
                                                            <label class="label">Conta Corrente / Cartão</label>
                                                            <ValidationProvider rules="required" name="model.contasCorrentes" v-slot="{ errors, valid }">
                                                                <b-field :label="$t('CONTASPAGARCADASTRO.CONTACORRENTE')"
                                                                         :message="errors"
                                                                         :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                                                    <b-select :placeholder="$t('CONTASPAGARCADASTRO.PLACEHOLDERCONTACORRENTE')" class="is-fullwidth" v-model="model[index].contaCorrente.id">
                                                                        <option v-for="(item, i) in contasCorrentes" v-bind:key="i" :value="item.id">{{item.nome}}</option>
                                                                    </b-select>
                                                                </b-field>
                                                            </ValidationProvider>
                                                        </td>
                                                        <td v-if="parcela.especieDePagamento.nome == 'Cheque'">
                                                            <label class="label">{{$t('CONTASPAGARCADASTRO.CHEQUENUMERO')}}</label>
                                                            <b-input maxlength="10" type="text" v-model="parcela.chequeNumero"></b-input>
                                                        </td>
                                                    </template>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="panel-block">
                                        <table class="table is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <td width="12%"></td>                                                    
                                                    <td width="10%"></td>
                                                    <td width="10%"></td>                                                    
                                                    <td width="10%"></td>    
                                                    <td width="10%"></td>
                                                    <td width="10%"></td>
                                                    <td width="20%"></td>
                                                    <td width="20%"><b>Total parcelas: {{ totalContas | currency }}</b></td>
                                                </tr>                                                
                                            </thead>
                                        </table>
                                    </div>
                                    <div class="box">
                                        <article class="level">
                                            <div class="level-item">
                                                <button type="button" class="button is-info is-fullwidth" @click="addParcela()">
                                                    {{$t('CONTASPAGARCADASTRO.ADICIONARPARCELA')}}
                                                </button>
                                            </div>
                                        </article>
                                    </div>
                                </article>
                            </div>
                        </article>
                    </div>
                </div>
                <nav v-if="!cadastroRecorrencia"  class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('CONTASPAGARCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('CONTASPAGARCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
            </ValidationObserver>
</template>


<script>
    import { mapGetters, mapState  } from 'vuex'    
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import modalCancelarFatura from "@/components/financeiro/modalCancelarFatura.vue"

    export default {
        props: {
            model: Array,
            index: {
                type: Number,
                default: 0
            },              
            cadastroRecorrencia: {
                type: Boolean,
                default: false
            }  
                               
       },        
        data() {
            return {
                totalContas: 0.0,
                totalCentroResultados: 0.0,
                categoriasFinanceiras: [],
                centrosResultadosCadastro: [],
                contasCorrentes: [],
                fornecedores: [],
                especies: [],
                salvandoDados: false
            }
        },
        created() {
        
            this.$http.get('/api/search/CategoriasFinanceirasDespesas')
                .then(res => {
                    this.categoriasFinanceiras = res.data;
                });

            this.$http.get('/api/search/CentrosResultados')
                .then(res => {
                    this.centrosResultadosCadastro = res.data;
                });

            this.$http.get('/api/search/contascorrentes')
                .then(res => {
                    this.contasCorrentes = res.data;
                });

            this.$http.get('/api/search/fornecedores')
                .then(res => {
                    this.fornecedores = res.data;
                });

            this.$http.get('/api/search/especies')
                .then(res => {
                    this.especies = res.data;
                });

            setTimeout(() => {this.recalcularParcelas();},3000);

            setTimeout(() => {this.recalcularTotalCentroResultados();},3000);
            
        },
        components: {            
            historicoAlteracoes,
            searchIdName
        },        
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config'
            ])
        },        
        methods:{
            modalCancelarFatura() {
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalCancelarFatura,
                        props: {
                            model: this.model[this.index],
                            contaPagar: true                            
                        },

                        hasModalCard: true,
                        trapFocus: true
                    })
                
            },
            recalcularValoresCentroResultados(item){
                item.valor = parseFloat((item.porcentagem / 100) * this.totalContas).toFixed(2);
            },
            recalcularTotalCentroResultados(valorAlterado){
                if(this.model[this.index].centrosResultados == null){
                    this.model[this.index].centrosResultados = [];
                }

                this.totalCentroResultados = parseFloat(0.0);

                this.model[this.index].centrosResultados.filter(e=>e.show).forEach((cr) => {

                    if(!valorAlterado){
                        this.recalcularValoresCentroResultados(cr);
                    }
                    
                    cr.valor = (!cr.valor) ? 0.0 : cr.valor;
                    
                    if(cr.valor>=0){
                        this.totalCentroResultados = parseFloat(this.totalCentroResultados) + parseFloat(cr.valor);
                    }

                });
                this.totalCentroResultados = parseFloat(this.totalCentroResultados).toFixed(2);

                this.$forceUpdate();
            },
            alterouValor(item){
                this.recalcularParcelas();

                item.porcentagem = parseFloat((item.valor / this.totalContas) * 100).toFixed(3);

                this.recalcularTotalCentroResultados(true);
            },
            alterouPorcentagem(item){
                this.recalcularParcelas();

                this.recalcularTotalCentroResultados();
            },
            addCentroResultado() {
                this.model[this.index].centrosResultados.push({
                    id: 0,
                    porcentagem: null,
                    centroResultados: {
                        id: this.centrosResultadosCadastro[0].id,
                        registroNomes: this.centrosResultadosCadastro[0].registroNomes
                    },
                    pagar: null,
                    show: true,
                })

                this.$forceUpdate();   
            },            
            deleteCentroResultado (item,j) {
                if(item.id==0){
                    this.model[this.index].centrosResultados.splice(j, 1);
                }else{
                    item.show = false; 
                }                
                this.$forceUpdate();                
            }, 
            onSubmit() {
                const especieNaoSelecionada = [];

                this.model[this.index].parcelas.forEach(parcela => {
                    if (parcela.especieDePagamento.id === 0)
                        especieNaoSelecionada.push(false)
                });

                console.log(this.totalCentroResultados, 'centro')
                console.log(this.totalContas, 'totalContas')
                
                this.recalcularParcelas();
                this.recalcularTotalCentroResultados();
                if(this.model[this.index].centrosResultados != null && 
                    this.model[this.index].centrosResultados.length > 0 &&
                    this.totalCentroResultados != this.totalContas){
                        this.$buefy.dialog.alert({
                            title: this.$t('CONTASPAGARCADASTRO.ATENCAO'),
                            message: this.$t('CONTASPAGARCADASTRO.TOTALCENTROECONTASINVALIDO'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });       
                        return;
                }

                if(especieNaoSelecionada.includes(false)){
                    this.$buefy.dialog.alert({
                            title: this.$t('CONTASPAGARCADASTRO.ATENCAO'),
                            message: this.$t('CONTASPAGARCADASTRO.CAMPOESPECIEOBRIGATORIO'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });       
                    return;
                }

                this.salvandoDados = true;                
                this.$http.post(`/api/financeiro/pagar`, this.model[this.index] )
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.push({ name: 'financeiro' });
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });       
            },
            addParcela() {
                this.model[this.index].parcelas.push({
                    parcelaPagar: false,
                    id: null,
                    chequeNumero: null,
                    contaCorrente: {
                        id: null
                    },
                    dataDeCancelamento: null,
                    dataDePagamento: null,
                    dataDeVencimento: this.model[this.index].parcelas.length > 0 ? 
                                      this.$moment(this.model[this.index].parcelas[this.model[this.index].parcelas.length-1].dataDeVencimento).add(30, 'days').toDate() :
                                      new Date(),
                    especieDePagamento: { id: 0, nome: null},
                    funcionarioCancelamento: {
                        id: null,
                        nome: null
                    },
                    funcionarioPagamento: {
                        id: null,
                        nome: null
                    },
                    motivoCancelamento: null,
                    valor: 0,
                    multa: 0,
                    juros: 0,
                    valorParcial:0,
                    situacao: 'Pendente',
                    pagar: {
                        id: null
                    }
                });
            },
            pagarParcela(i){
                this.model[this.index].parcelas[i].dataDePagamento = this.$moment().toDate();
                this.model[this.index].parcelas[i].situacao = 'Pago';
                this.model[this.index].parcelas[i].parcelaPagar = true;
            },
            cancelarParcela(i){
                const parcela = this.model[this.index].parcelas[i];

                if(parcela.id==null){                    
                    this.model[this.index].parcelas.splice(i,1);                   
                }else{
                    this.model[this.index].parcelas[i].dataDeCancelamento = this.$moment().toDate();
                    this.model[this.index].parcelas[i].situacao = 'Cancelado';
                }

                this.recalcularParcelas();
            },
            recalcularParcelas() {                
                
                this.totalContas = parseFloat(0.0);
                this.model[this.index].parcelas.filter(e=>e.situacao != 'Cancelado').forEach((p) => {

                    p.valor = (!p.valor) ? 0.0 : p.valor;   
                    p.valorParcial = ( !p.valorParcial) ? 0.0 : p.valorParcial;   
                    p.multa = (!p.multa) ? 0.0 : p.multa;   
                    p.juros = ( !p.juros) ? 0.0 : p.juros;   
                                        
                    if(p.valor>=0){            
                        
                        p.valor = (parseFloat(p.valorParcial?.toString()) + parseFloat(p.multa?.toString()) + parseFloat(p.juros?.toString())).toFixed(2);  
                        this.totalContas = parseFloat(this.totalContas) + parseFloat(p.valor);                        
                    }
                    
                });
                this.totalContas = parseFloat(this.totalContas).toFixed(2);  
                
                this.$forceUpdate();

            }, 
        }
    }
</script>