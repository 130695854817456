<template>
  <section>    
    <b-modal
      :active.sync="isOpenModalData"
      has-modal-card
      trap-focus
      aria-role="dialog"
      full-screen
      aria-modal
    >
      <modalLocalizarContas :titulo="$t('LOTECONTASPAGARCADASTRO.TITULO')"
        :items.sync="localizarModalData"
        :rows.sync="itemsSelecionados"
        @link="abrirPagar"
      ></modalLocalizarContas>
    </b-modal>

    <titlebar :title-stack="titleStack"> </titlebar>
    <b-loading :active.sync="isLoading"></b-loading>

      <b-tabs position="is-right" multiline>
          <b-tab-item >
              <template v-if="!model.id" slot="header">
                  <span >Lote</span>
              </template>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical"
                      :class="{ 'is-9': model.id, 'is-12': !model.id }">
                      <article class="tile is-child box">
                        <div class="columns">
                          <div class="column is-12">
                            <b-field :label="$t('LOTECONTASPAGARCADASTRO.DESCRICAO')">
                              <b-input v-model="model.descricao" type="text" />
                            </b-field>
                          </div>     
                        </div>
                        <div class="columns">
                          <div class="column">
                            <b-field :label="$t('LOTECONTASPAGARCADASTRO.TIPOBAIXA')">
                              <b-select
                                placeholder=""
                                v-model="model.tipoPagarParcelaLote"
                                expanded
                              >
                                <option
                                  v-for="tipoDeBaixa in tiposDeBaixas"
                                  :value="tipoDeBaixa.value"
                                  :key="tipoDeBaixa.value"
                                >
                                  {{ tipoDeBaixa.text }}
                                </option>
                              </b-select>
                            </b-field>
                          </div>
                          <div class="column">
                            

                            <b-field :label="$t('LOTECONTASPAGARCADASTRO.CONTACORRENTE')">
                              <b-select
                                placeholder=""
                                v-model="model.contaCorrente.id"
                                expanded
                              >
                                <option
                                  v-for="option in contas"
                                  :value="option.value"
                                  :key="option.value"
                                >
                                  {{ option.text }}
                                </option>
                              </b-select>
                            </b-field>
                                                                             

                          </div>                          
                        </div>                        

                        <div class="columns">
                            <div class="column is-12">
                                <b-field :label="$t('LOTECONTASPAGARCADASTRO.OBSERVACAO')">
                                    <b-input maxlength="5000" type="textarea" v-model="model.observacao"></b-input>
                                </b-field>
                            </div>
                        </div>                        
                      </article>
                      
                      <article class="tile is-child box" >
                        <div class="panel-heading has-text-centered  is-light fullwidth" >
                          {{$t('LOTECONTASPAGARCADASTRO.LOCALIZARCONTAS')}}
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <div class="panel">
                                <p class="panel-tabs">
                                    <a :class="{ 'is-active': status == 'emissao' }" @click="status = 'emissao'">{{$t('LOTECONTASPAGARCADASTRO.EMISSAO')}}</a>
                                    <a :class="{ 'is-active': status == 'vencimento' }" @click="status = 'vencimento'">{{$t('LOTECONTASPAGARCADASTRO.VENCIMENTO')}}</a>
                                </p>
                                <div class="panel-block">
                                    <periodoHora :dataInicial.sync="datas[0]"
                                        :dataFinal.sync="datas[1]"/>
                                </div>
                            </div>
                          </div>
                        </div>                        
                        <div class="columns">

                          <div class="column">
                            <searchIdName table="unidade" :label="$t('LOTECONTASPAGARCADASTRO.UNIDADE')"
                              :id.sync="model.unidades"/>
                          </div>
                          <div class="column">
                            <b-field :label="$t('LOTECONTASPAGARCADASTRO.CATEGORIAFINANCEIRA')">
                                <b-select expanded :placeholder="$t('CONTASPAGARCADASTRO.CATEGORIAFINANCEIRA')" class="is-fullwidth" v-model="model.categoriasFinanceiras">
                                    <option v-for="(item, x) in categoriasFinanceiras" v-bind:key="x" :value="item.id">{{item.registroNomes}}</option>
                                </b-select>
                            </b-field>      
                          </div>
                        </div>                        
                        <div class="columns">
                          <div class="column">
                            <p class="buttons">
                              <b-button
                                type="is-primary"
                                v-if="model.situacaoLoteContasPagar === 1 || !model.id"
                                native-type="button"
                                class="centered"
                                @click.prevent.stop="localizarContas()"
                              >
                                {{$t('LOTECONTASPAGARCADASTRO.LOCALIZARCONTAS')}}
                              </b-button>
                            </p>
                          </div>
                        </div>
                      </article>                      

                      <br />
                      <article class="tile is-child box" v-if="items.length">
                        <div class="panel-heading has-text-centered button is-light fullwidth" >
                          {{$t('LOTECONTASPAGARCADASTRO.CONTAS')}}
                        </div>
                        <div class="panel">
                          <b-table :data="items" striped hoverable>
                            <template >
                              <b-table-column  v-slot="props" field="documento" :label="$t('LOTECONTASPAGARCADASTRO.DOCUMENTO')">
                                <a @click="abrirPagar(props.row)" class="is-primary">
                                  {{ props.row.documento }}</a
                                >
                              </b-table-column>

                              <b-table-column  v-slot="props"
                                field="fornecedor"
                                :label="$t('LOTECONTASPAGARCADASTRO.FORNECEDOR')"
                              >
                                {{ props.row.fornecedor }}
                              </b-table-column>

                              <b-table-column  v-slot="props" field="notaFiscal" :label="$t('LOTECONTASPAGARCADASTRO.NOTAFISCAL')">
                                {{ props.row.notaFiscal }}
                              </b-table-column>
                              <b-table-column  v-slot="props"
                                field="contaCorrente"
                                :label="$t('LOTECONTASPAGARCADASTRO.CONTACORRENTE')"
                              >
                                {{ props.row.nome }}
                              </b-table-column>
                              <b-table-column  v-slot="props" field="dataEmissao" :label="$t('LOTECONTASPAGARCADASTRO.EMISSAO')">
                                {{ props.row.dataPagar | data }}
                              </b-table-column>
                              <b-table-column  v-slot="props" field="dataDeVencimento" :label="$t('LOTECONTASPAGARCADASTRO.VENCIMENTO')">
                                {{ props.row.dataDeVencimento | data }}
                              </b-table-column>
                              <b-table-column  v-slot="props" field="observacoes" :label="$t('LOTECONTASPAGARCADASTRO.OBSERVACAO')">
                                {{ props.row.observacao }}
                              </b-table-column>
                              <b-table-column  v-slot="props" field="valor" :label="$t('LOTECONTASPAGARCADASTRO.VALOR')">
                                {{ props.row.valor | currency }}
                              </b-table-column>
                              <b-table-column  v-slot="props" field="" label="">
                                <b-button
                                  v-if="
                                    model.situacaoLoteContasPagar === 1 || !model.id
                                  "
                                  icon-left="close"
                                  type="is-danger"
                                  @click.prevent.stop="removerItem(props.row)"
                                ></b-button>
                              </b-table-column>
                            </template>
                            <template slot="footer">
                              <th class="is-hidden-mobile"></th>
                              <th class="is-hidden-mobile"></th>
                              <th class="is-hidden-mobile"></th>
                              <th class="is-hidden-mobile"></th>
                              <th class="is-hidden-mobile"></th>
                              <th class="is-hidden-mobile">TOTAL:</th>
                              <th class="is-hidden-mobile">
                                {{ totalContas | currency }}
                              </th>
                              <th class="is-hidden-mobile"></th>
                              <th class="is-hidden-mobile"></th>
                            </template>
                          </b-table>
                        </div>
                      </article>
                    </div>

                    <div class="tile is-parent" v-if="model.id">
                      <article class="tile is-child">
                        <article class="panel">
                          <div class="panel-heading has-text-centered">
                            {{$t('LOTECONTASPAGARCADASTRO.SITUACAO')}}
                          </div>
                          <div class="panel-block has-text-centered">
                            <p
                              v-if="model.situacaoLoteContasPagar == 1"
                              class="tile is-child notification is-light is-12 fix-size"
                            >
                              {{$t('LOTECONTASPAGARCADASTRO.PENDENTE')}}
                            </p>
                            <p
                              v-if="model.situacaoLoteContasPagar == 2"
                              class="tile is-child notification is-success is-12 fix-size"
                            >
                              {{$t('LOTECONTASPAGARCADASTRO.PAGO')}}
                            </p>
                            <p
                              v-if="model.situacaoLoteContasPagar == 3"
                              class="tile is-child notification is-danger is-12 fix-size"
                            >
                              {{$t('LOTECONTASPAGARCADASTRO.CANCELADO')}}
                            </p>
                          </div>
                          <br />
                          <div v-if="model.id && model.situacaoLoteContasPagar == 1">
                            <div class="panel-block" v-if="isInRole('pagar-alterar')">
                              <b-button
                                type="is-success"
                                native-type="button"
                                expanded
                                icon-left="download"
                                @click.prevent.stop="baixa"
                              >
                                {{$t('LOTECONTASPAGARCADASTRO.BAIXAR')}}
                              </b-button>
                            </div>
                            <div class="panel-block is-flex-direction-column" v-if="baixar">
                              <b-field label="Espécie" class="fullwidth" expanded>
                                <b-select
                                  placeholder=""
                                  v-model="baixar.especie.id"
                                  expanded
                                  class="fullwidth"
                                >
                                  <option
                                    v-for="option in especies"
                                    :value="option.id"
                                    :key="option.id"
                                  >
                                    {{ option.nome }}
                                  </option>
                                </b-select>
                              </b-field>
                              <b-field :label="$t('LOTECONTASPAGARCADASTRO.DATABAIXA')" class="fullwidth" expanded>
                                <b-datetimepicker v-model="baixar.dataBaixa"
                                                  icon="calendar-today"
                                                  class="fullwidth"
                                                  expanded>
                                </b-datetimepicker>
                              </b-field>
                            </div>

                            <div
                              class="panel-block"
                              v-if="baixar && baixar.especie.id == 3"
                            >
                              <b-field :label="$t('LOTECONTASPAGARCADASTRO.BANDEIRA')" class="fullwidth">
                                <b-select
                                  placeholder=""
                                  v-model="baixar.bandeira.id"
                                  class="fullwidth"
                                  expanded
                                >
                                  <option
                                    v-for="option in bandeiras"
                                    :value="option.value"
                                    :key="option.value"
                                  >
                                    {{ option.text }}
                                  </option>
                                </b-select>
                              </b-field>
                            </div>

                            <div class="panel-block" v-if="baixar">
                              <b-button
                                type="is-primary"
                                native-type="button"
                                expanded
                                @click.prevent.stop="confirmarBaixar"
                              >
                                {{$t('SISTEMA.CONFIRMAR')}}
                              </b-button>
                            </div>
                            <br v-if="baixar" />
                          </div>
                        </article>
                      </article>
                    </div>
                  </div>
                  <nav class="level">
                    <div class="level-item">
                      <p class="buttons">
                        <b-button
                          type="is-success"
                          v-if="
                            (model.situacaoLoteContasPagar === 1 || !model.id) &&
                            isInRole('pagar-parcela-lote-alterar')
                          "
                          :loading="salvandoDados"
                          native-type="submit"
                          icon-left="check-circle"
                        >
                          {{$t('LOTECONTASPAGARCADASTRO.SALVAR')}}
                        </b-button>
                        <b-button
                          type="is-danger"
                          native-type="button"
                          icon-left="close-circle"
                          @click="$router.back()"
                        >
                          {{$t('LOTECONTASPAGARCADASTRO.CANCELAR')}}
                        </b-button>
                      </p>
                    </div>
                  </nav>
                </form>
              </ValidationObserver>                                
          </b-tab-item>
          <b-tab-item v-if="!model.id">
              <template  slot="header">
                  <span @click="inicializaCamposRecorrencia()" >Recorrência</span>                  
              </template>
                <div class="tile is-parent is-vertical is-12">
                    <article class="tile is-child box">
                        <div class="columns">
                            <div class="column is-6">
                                <b-field :label="$t('LOTECONTASPAGARCADASTRO.TOTAL')">
                                  <b-input v-model="recorrenciaModel.valorTotal" type="number"  step=".00000001"></b-input>                                  
                                </b-field>                             
                            </div>                                
                            <div class="column is-3">
                                <b-field :label="$t('LOTECONTASPAGARCADASTRO.RECORRENCIAS')">
                                  <b-input v-model="recorrenciaModel.recorrenciaNumero" type="number" min="1"  max="24"></b-input>
                                </b-field>
                            </div>
                            <div class="column is-3">
                                <b-field :label="$t('LOTECONTASPAGARCADASTRO.PARCELAS')">
                                  <b-input v-model="recorrenciaModel.parcelasQtde" type="number" min="1"  max="24"></b-input>
                                </b-field>
                            </div>
                        </div>                      
                        <div class="columns">
                            <div class="column is-6">
                                <b-field :label="$t('LOTECONTASPAGARCADASTRO.VENCIMENTO')">
                                    <b-datepicker v-model="recorrenciaModel.dataVencimento"
                                                      :disabled="model.id > 0"                                                      
                                                      icon="calendar-today" expanded>
                                    </b-datepicker>
                                </b-field>                             
                            </div>                                
                            <div class="column is-6">
                                <b-field :label="$t('LOTECONTASPAGARCADASTRO.PRAZODIAS')">
                                  <b-input  v-model="recorrenciaModel.prazoDias" type="number"  max="500" min="0"></b-input>
                                </b-field>
                            </div>

                        </div>

                        <div class="columns">
                            <div class="column is-12">
                                <ValidationProvider name="descrição" rules="required" v-slot="{ errors, valid }">
                                    <b-field :label="$t('LOTECONTASPAGARCADASTRO.DESCRICAO')"
                                              :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                              :message="errors">
                                        <b-input type="text" v-model="recorrenciaModel.descricao"></b-input>
                                    </b-field>
                                </ValidationProvider>
                            </div>                                                
                        </div>                            
                        <div class="columns">
                            <div class="column is-6">
                              <b-field :label="$t('LOTECONTASPAGARCADASTRO.TIPOBAIXA')">
                                <b-select
                                  placeholder=""
                                  v-model="recorrenciaModel.tipoPagarParcelaLote"
                                  expanded
                                >
                                  <option
                                    v-for="tipoDeBaixa in tiposDeBaixas"
                                    :value="tipoDeBaixa.value"
                                    :key="tipoDeBaixa.value"
                                  >
                                    {{ tipoDeBaixa.text }}
                                  </option>
                                </b-select>
                              </b-field>                              
                            </div>   
                            <div class="column is-6">       
                              <b-field :label="$t('CONTASRECEBERCADASTRO.ESPECIE')">
                                  <b-select  v-model="recorrenciaModel.especie.id" expanded >
                                      <option v-for="option in especies"
                                              :value="option.id"
                                              :key="option.id">
                                          {{ option.nome }}
                                      </option>
                                  </b-select>
                              </b-field>
                            </div>                                  
                        </div>

                        <div class="columns">
                            <div class="column is-6">
                                <search-id-name :id.sync="recorrenciaModel.unidadeId" :label="$t('LOTECONTASPAGARCADASTRO.UNIDADE')" table="Unidade"></search-id-name>
                            </div>
                            <div class="column is-6">
                              <b-field :label="$t('LOTECONTASPAGARCADASTRO.CATEGORIAFINANCEIRA')">
                                  <b-select expanded :placeholder="$t('CONTASPAGARCADASTRO.CATEGORIAFINANCEIRA')" class="is-fullwidth" v-model="recorrenciaModel.categoriaFinanceira.id">
                                      <option v-for="(item, x) in categoriasFinanceiras" v-bind:key="x" :value="item.id">{{item.registroNomes}}</option>
                                  </b-select>
                              </b-field>                               
                            </div>                         
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                    <b-field :label="$t('LOTECONTASPAGARCADASTRO.NOTAFISCAL')">
                                        <b-input type="text" v-model="recorrenciaModel.notaFiscal"></b-input>
                                    </b-field>                                
                            </div>
                            <div class="column is-6" v-if="config.naoVincularContaPagarContaCorrente == false">
                                <ValidationProvider rules="required" name="conta corrente" v-slot="{ errors, valid }">
                                    <b-field :label="$t('LOTECONTASPAGARCADASTRO.CONTACORRENTE')"
                                              :message="errors"
                                              :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                        <b-select expanded :placeholder="$t('LOTECONTASPAGARCADASTRO.PLACEHOLDERCONTACORRENTE')" class="is-fullwidth" v-model="recorrenciaModel.contaCorrente.id" :readonly="model && model.id > 0">
                                            <option v-for="(item, index) in contas" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                        </b-select>
                                    </b-field>
                                </ValidationProvider>
                            </div>       
                        </div> 
                        <div class="columns">
                            <div class="column is-6">
                                  <b-field :label="$t('LOTECONTASPAGARCADASTRO.CENTRORESULTADO')">                              
                                    <b-select  @input="alterouCentroResultado('centroResultado')"  expanded v-model="recorrenciaModel.centrosResultados">
                                        <option v-for="(option, j) in centrosResultadosCadastro"
                                                :value="option.id"
                                                :key="j">
                                            {{ option.registroNomes }}
                                        </option>
                                    </b-select>                                
                                  </b-field>
                            </div>
                            <div class="column is-6">
                                <ValidationProvider name="fornecedor" rules="required" v-slot="{ errors, valid }">
                                    <b-field :label="$t('LOTECONTASPAGARCADASTRO.FORNECEDOR')"
                                              :message="errors"
                                              :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                        <b-select expanded  class="is-fullwidth" v-model="recorrenciaModel.fornecedor.id" :readonly="model && model.id > 0">
                                            <option v-for="(item, index) in fornecedores" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                        </b-select>
                                    </b-field>
                                </ValidationProvider>
                            </div>
                        </div>        
                        <div class="columns">
                          <div class="column is-6">
                                <b-field :label="$t('LOTECONTASPAGARCADASTRO.REGRARATEIO')">                              
                                    <b-select  @input="alterouCentroResultado('regraRateio')"   expanded v-model="recorrenciaModel.regrasRateioId" >
                                        <option v-for="(option, j) in regrasRateioCadastro" :value="option.id" :key="j">
                                            {{ option.nome }}
                                        </option>
                                    </b-select>                                                        
                                </b-field>
                            </div>
                        </div>                                            
                        <div class="columns">
                            <div class="column is-12">
                                <b-field :label="$t('CONTASPAGARCADASTRO.OBSERVACAO')">
                                    <b-input maxlength="5000" type="textarea" v-model="recorrenciaModel.observacao"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <nav class="level">
                          <div class="level-item">
                            <p class="buttons">

                              <b-button v-if="recorrencias.length == 0" type="is-info" 
                               icon-left="clock"  @click="calcularRecorrencia()"
                                :title="$t('LOTECONTASPAGARCADASTRO.CALCULAR')">{{$t('LOTECONTASPAGARCADASTRO.CALCULAR')}}</b-button>

                              <b-button v-if="recorrencias.length > 0" type.native="button" type="is-success" 
                               icon-left="check-circle"  @click="confirmarRecorrencia()"
                                :title="$t('LOTECONTASPAGARCADASTRO.SALVAR')">{{$t('LOTECONTASPAGARCADASTRO.SALVAR')}}</b-button>

                              <b-button  type="is-warning" 
                               icon-left="close-circle"  @click="limparRecorrencia()"
                                :title="$t('LOTECONTASPAGARCADASTRO.LIMPAR')">{{$t('LOTECONTASPAGARCADASTRO.LIMPAR')}}</b-button>                                

                            </p>
                          </div>
                        </nav>                           
                    </article>
                    <article v-if="recorrencias.length > 0" >
                      <div class="tile is-child box">

                        <b-tabs position="is-left"  type="is-boxed" multiline>
                            <b-tab-item  v-for="(contasPagar, index) in recorrencias" :key="index" >
                                <template slot="header">
                                    <span >Recorrência {{index + 1}}</span>
                                </template>
                                <contaPagar :model="recorrencias" :index="index" :cadastroRecorrencia="true" ></contaPagar>
                            </b-tab-item>
                        </b-tabs>                        

                      </div>                        
                    </article>
                </div>                                     
          </b-tab-item>
      </b-tabs>        

  </section>
</template>

<style scoped>


.fix-size {
  max-height: 1.5rem;
  padding-top: 0rem;
}
.btn-action {
  margin: 0.2rem;
}

.label-parcela-cancelada {
  padding-top: 0.25rem;
}

.collapse-content {
  width: 100% !important;
}

.panel .control {
  max-width: 210px;
}

.centered {
  margin: 0 auto;
}

.fullwidth {
  width: 100%;
  max-width: 100% !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import titlebar from "@/components/titlebar.vue";
import modalLocalizarContas from "@/components/financeiro/modalLocalizarContas.vue";
import moment from "moment";
import searchIdName from "@/components/searchIdName";
import periodoHora from "@/components/periodoHora.vue";
import contaPagar from '@/components/financeiro/contaPagar.vue'

export default {
  data() {
    return {
      model: {
        id: null,
        descricao: null,
        observacoes: null,
        especie: {
          id: 0,
        },
        contaCorrente: {
          id: 0,
        },
        unidades: null,
        categoriasFinanceiras: null,
      },
      datas: [
          this.$moment().subtract(1, 'days').toDate(),
          this.$moment().toDate()
      ],
      status: 'emissao',
      contas: [],
      salvandoDados: false,
      isOpenModalData: false,
      localizarModalData: null,
      erroCadastro: [],
      tiposDeBaixas: [],
      especies: [],
      isLoading: false,
      itemsSelecionados: [],
      items: [],
      totalContas: 0,
      baixar: null,
      idsExcluidos: [],
      
      fornecedores: [],
      categoriasFinanceiras: [],
      recorrencias:[],
      centrosResultadosCadastro: [],
      regrasRateioCadastro: [],

      recorrenciaModel:{
        valorTotal:0.0,
        recorrenciaNumero:1,
        parcelasQtde: 1,
        dataVencimento: this.$moment().toDate(),
        prazoDias: 30,
        descricao: null,
        tipoPagarParcelaLote: 0,    
        unidades: null,        
        centrosResultados: null,   
        regrasRateioId: null,   
        regrasRateio: null, 
        categoriaFinanceira: {
          id: 0,
        },        
        notaFiscal: null,           
        contaCorrente: {
          id: 0,
        },
        fornecedor: {
            id: null,
        },
        especie: {
          id: 0,
          nome: null
        },
        observacao: null,
      }
    };
    
  },
  
  components: {
    titlebar,
    modalLocalizarContas,
    searchIdName,
    periodoHora,
    contaPagar
  },
  filters: {
    data: (valor) => {
      if (!valor) return null;

      return moment(valor).format("DD/MM/YYYY");
    },
  },
  created() {
    this.isLoading = true;
    let id = "";

    this.$http.get('/api/search/CentrosResultados')
        .then(res => {
            this.centrosResultadosCadastro = res.data;
        });

    this.$http.get('/api/search/RegrasRateio')
        .then(res => {
            this.regrasRateioCadastro = res.data;
        });        
        
    this.$http.get('/api/search/fornecedores')
        .then(res => {
            this.fornecedores = res.data;
        });

    this.$http.get('/api/search/CategoriasFinanceirasDespesas')
        .then(res => {
            this.categoriasFinanceiras = res.data;
        })        

    if (this.$route.params.id) {
      id = "?id=" + this.$route.params.id;
    }
    this.$http
      .get("/api/financeiro/PagarParcelaLote" + id)
      .then((res) => {
        const model = res.data?.item;
        this.contas = res.data?.contasCorrentes;
        this.tiposDeBaixas = res.data?.tiposDeBaixas;
        this.especies = res.data?.especies;
        this.bandeiras = res.data?.bandeira;

        if (!model.especie) {
          model.especie = {
            id: 0,
          };
        }
        model.contaCorrente = !model.contaCorrente ? {
          id: this.contas[0]?.value,
        } : model.contaCorrente;
        
        if (model.itens) {
          this.items = model.itens.map((i) => {
            return {
              ...i,
              dataPagar: i.pagar?.data,
              notaFiscal: i.pagar.notaFiscal,
              nome: i.pagar?.contaCorrente?.nome,
              fornecedor: i.pagar?.fornecedor?.nome,
              documento: i.pagar?.id,
              observacao: i.pagar?.observacoes,
            };
          });
          this.recalcularTotal();
        }
        this.model = model;
        this.isLoading = false;
      })
      .catch((ex) => {
        this.isLoading = false;
      });
  },
  computed: {
    titleStack() {
      return [
        this.$t('SIDEBAR.FINANCEIRO'),
        this.$t('LOTECONTASPAGARLISTAR.TITULO'),
        this.$route.params.id ?? this.$t('SISTEMA.NOVO')
      ];
    },
    ...mapState(["unidadeId", "config", "integradores","usuario"]),    
    ...mapGetters(["isInRole"]),
  },
  watch: {
    itemsSelecionados: function (novoValor) {
      if (novoValor) {
        this.items.push(...novoValor);
        this.recalcularTotal();
      }
    },

  },
  methods: {
    alterouCentroResultado(campo){        
      if(campo=="centroResultado"){
        this.recorrenciaModel.regrasRateioId = null;
        this.recorrenciaModel.regrasRateios = null;
      }else{
        this.recorrenciaModel.centrosResultados = null;
        
        this.$http.get('/api/search/RegrasRateioPorId?id='+this.recorrenciaModel.regrasRateioId)
            .then(res => {
                this.recorrenciaModel.regrasRateio = res.data;
            });        
      }
    },    
    onSubmit() {
      this.$forceUpdate();
      this.isLoading = true;
      const newItem = this.getItem();
      const idsExcluidos = this.idsExcluidos.length
        ? "?idsExcluidos=" + this.idsExcluidos.map(i => i.id).join(",")
        : "";
      this.$http
        .post(`/api/financeiro/PagarParcelaLote`+idsExcluidos, newItem)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "financeiro" });
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
          if (err.body?.errors) {
            Object.keys(err.body.errors).forEach((x) => {
              this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
            });
          } else {
            const mensagem =
              err.status === 500
                ? "Foi encontrado um erro no servidor ao salvar o registro"
                : "Erro ao salvar registro";
            this.$buefy.toast.open({
              duration: 5000,
              message: mensagem,
              type: "is-danger",
              queue: false,
            });
          }
        });
    
    },
    getItem() {
      const novoItem = {};
      novoItem.id = this.model.id;
      novoItem.descricao = this.model.descricao;
      novoItem.contaCorrente = this.contas?.find(
        (c) => c.value == this.model.contaCorrente?.id
      );
      novoItem.dataInicial = this.datas[0];
      novoItem.dataFinal = this.datas[1];
      novoItem.unidades = this.model.unidades;
      novoItem.categoriasFinanceiras = this.model.categoriasFinanceiras;
      novoItem.dataCancelamento = this.model.dataCancelamento;
      novoItem.observacao = this.model.observacao;
      novoItem.itens = this.items?.map((i) => {
        const valor = {
          ...i,
        };

        return valor;
      });
      

      if (this.model.especie) {
        novoItem.especie = this.model.especie;
      }

      return novoItem;
    },

    getItemRecorrencia() {
      const novoItem = {};
      novoItem.id = this.recorrenciaModel.id;
      novoItem.descricao = this.recorrenciaModel.descricao;
      novoItem.contaCorrente = this.contas?.find(
        (c) => c.value == this.recorrenciaModel.contaCorrente?.id
      );
      novoItem.unidades = this.recorrenciaModel.unidades;      
      novoItem.dataCancelamento = this.recorrenciaModel.dataCancelamento;
      novoItem.observacao = this.recorrenciaModel.observacao;
      novoItem.tipoPagarParcelaLote = this.recorrenciaModel.tipoPagarParcelaLote;
      novoItem.recorrencias = this.recorrencias;
      novoItem.especie = this.recorrenciaModel.especie;
      return novoItem;
    },    
    localizarContas() {
      const index = this.items.length ? this.items.length : 1;
      this.localizarModalData = null;
      let unidades = this.model.unidades;
      let categoriasFinanceiras = this.model.categoriasFinanceiras;
      let filtrarPor = this.status;
      let dataInicial = this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss');
      let dataFinal = this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss');
      
      if (dataInicial) {
        dataInicial = `dataInicial=${dataInicial}`;
      } else {
        dataInicial = "";
      }
      if (dataFinal) {
        dataFinal = `&dataFinal=${dataFinal}`;
      } else {
        dataFinal = "";
      }

      if(unidades != null){
        unidades = `unidade=${unidades}`;
      }

      if(categoriasFinanceiras != null){
        categoriasFinanceiras = `categoriaFinanceiraId=${categoriasFinanceiras}`;
      }

      if(filtrarPor){
        filtrarPor = `filtrarPor=${filtrarPor}`
      }

      this.isLoading = true;
      this.$http
        .get(
          `/api/financeiro/ListarParcelasLotePagar?${filtrarPor}&${categoriasFinanceiras}&${unidades}&${dataInicial}${dataFinal}&index=${index}&_=${new Date().getTime()}`
        )
        .then((res) => {
          //filtrando os dados quejá existem
          const idsSelecionados = this.items?.map((i) => i.id);
          this.localizarModalData = res.data?.filter(
            (i) => idsSelecionados.indexOf(i.id) < 0
          );

            
          this.isOpenModalData = true;
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
          this.isLoading = false;
        });
    },
    abrirPagar(item) {
      this.$router.push({
        name: "contaspagar",
        params: { id: item.documento },
      });
    },
    removerItem(item) {
      if (this.model.id) {
        this.idsExcluidos.push(this.items?.find((i) => i.id == item.id));
      }
      this.items = this.items?.filter((i) => i.id !== item.id);
      this.recalcularTotal();
    },
    recalcularTotal() {
      this.totalContas = 0;
      this.items.forEach((v) => {
        this.totalContas += parseFloat(v.valor?.toString());
      });
      this.totalContas = this.totalContas.toFixed(2);
    },
    baixa() {
      this.baixar = {
        especie: {
          id: this.especies[0]?.id,
          nome: this.especies[0]?.nome,
        },
        bandeira: {
          id: this.bandeiras[1]?.id,
          nome: this.bandeiras[1]?.nome
        },
        dataBaixa: new Date()
      };
      this.$forceUpdate();
    },
    confirmarBaixar() {
      this.isLoading = true;
      try {
        const especie = this.baixar.especie;
        const dataBaixa = this.$moment(this.baixar.dataBaixa).format('YYYY-MM-DDTHH:mm:ss');
        this.$http
            .post(`/api/financeiro/PagarParcelaLoteBaixar?id=${this.model.id}&dataBaixa=${dataBaixa}`, especie)
            .then((res) => {
            this.isLoading = false;
            this.$router.push({ name: "financeiro" });
            })
            .catch((e) => {
            this.isLoading = false;
            console.error(e);
            this.$buefy.toast.open({
                duration: 5000,
                message: "Erro ao baixar",
                type: "is-danger",
                queue: false,
            });
            });
      }catch(e){
          this.isLoading = false;
      }
    },

    inicializaCamposRecorrencia(){
      this.recorrenciaModel= {
        valorTotal:0.0,
        recorrenciaNumero:1,
        parcelasQtde: 1,
        dataVencimento: this.$moment().toDate(),
        prazoDias: 30,
        descricao: null,
        tipoPagarParcelaLote: 0,    
        unidades: null,
        regrasRateioId: null,
        regrasRateio: null,
        centrosResultados: null,        
        categoriaFinanceira:{
          id: 0,
        },
        contaCorrente: {
          id: 0,
        },
        fornecedor: {
            id: null,
        },
        especie: {
          id: 0,
          nome: null
        },       
        observacao: null,
      };
    },
    calcularRecorrencia() {
      
      this.isLoading = true;      
      this.recorrencias = [];             
      this.adicionarRecorrencias();
              
    },
    calcularParcelas(dataVencimento, especie, valorTotalParcelas, qtdeParcela) {
      const parcelas = [];
      const valorParcela = parseFloat(valorTotalParcelas / qtdeParcela).toFixed(2);
      const valorPrimeiraParcela = valorTotalParcelas - (valorParcela * (qtdeParcela - 1));

      let countParcelas = 0;

      for(let i = 1; i <= qtdeParcela; i++) {
        let valor = 0;
        if(countParcelas == 0) {
          valor =  valorPrimeiraParcela.toFixed(2)
          countParcelas += 1;
        }else {
          valor = valorParcela
        }

        if(i>1){
          dataVencimento =  this.$moment(dataVencimento).add("d",this.recorrenciaModel.prazoDias).toDate();            
        }

        parcelas.push({
            parcelaPagar: false,
            id: null,
            chequeNumero: null,
            contaCorrente: {
                id: null
            },
            dataDeCancelamento: null,
            dataDePagamento: null,
            dataDeVencimento: dataVencimento,
            especieDePagamento: { 
              id: especie.id, 
              nome: especie.nome
            },
            funcionarioCancelamento: {
                id: null,
                nome: null
            },
            funcionarioPagamento: {
                id: null,
                nome: null
            },
            motivoCancelamento: null,
            valor: valor,
            multa: 0,
            juros: 0,
            valorParcial: valor,
            situacao: 'Pendente',
            pagar: {
                id: null
            }  
          }); 
      }

      return parcelas;
    },
    adicionarRecorrencias(){

      if(!this.recorrenciaModel.regrasRateioId && !this.recorrenciaModel.centrosResultados){

            this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('LOTECONTASPAGARCADASTRO.CAMPOCENTRORESULTADO'),
                    type: 'is-warning',
                    hasIcon: true                                          
                });
            this.isLoading = false;                     
            return true;          
      }   
      
      if(!this.recorrenciaModel.especie || this.recorrenciaModel.especie.id <= 0){

        this.$buefy.dialog.alert({
                title: this.$t('SISTEMA.ATENCAO'),
                message: this.$t('LOTECONTASPAGARCADASTRO.CAMPOESPECIE'),
                type: 'is-warning',
                hasIcon: true                                          
            });
        this.isLoading = false;                     
        return true;          
      }   

      if(!this.recorrenciaModel.categoriaFinanceira || this.recorrenciaModel.categoriaFinanceira.id <= 0){

            this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('LOTECONTASPAGARCADASTRO.CAMPOCATEGORIAFINANCEIRA'),
                    type: 'is-warning',
                    hasIcon: true                                          
                });
            this.isLoading = false;                     
            return true;          
      }

      if(!this.recorrenciaModel.fornecedor || this.recorrenciaModel.fornecedor.id <= 0){

            this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('LOTECONTASPAGARCADASTRO.CAMPOFORNECEDOR'),
                    type: 'is-warning',
                    hasIcon: true                                          
                });
            this.isLoading = false;                     
            return true;          
      }
            
      if(!this.recorrenciaModel.contaCorrente || this.recorrenciaModel.contaCorrente.id <= 0){

            this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('LOTECONTASPAGARCADASTRO.CAMPOCONTACORRENTE'),
                    type: 'is-warning',
                    hasIcon: true                                          
                });
            this.isLoading = false;                     
            return true;          
      }

      if(!this.recorrenciaModel.valorTotal || this.recorrenciaModel.valorTotal <= 0){

            this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('LOTECONTASPAGARCADASTRO.CAMPOVALORTOTAL'),
                    type: 'is-warning',
                    hasIcon: true                                          
                });
            this.isLoading = false;                     
            return true;          
      }

      setTimeout(() => {
        let dataVencimento = this.recorrenciaModel.dataVencimento;
        const especie = this.especies.find(especie => especie.id === this.recorrenciaModel.especie.id);
        const totalDeRecorrencias = this.recorrenciaModel.recorrenciaNumero;       

        for(let i = 1; i <= this.recorrenciaModel.recorrenciaNumero;i++){           

          if(i>1){
            dataVencimento =  this.$moment(dataVencimento).add("d",this.recorrenciaModel.prazoDias).toDate();            
          }
          
          const parcela = this.calcularParcelas(dataVencimento, especie, this.recorrenciaModel.valorTotal, this.recorrenciaModel.parcelasQtde);
          const centrosResultadosRecorrencia = []; 
          
          if(this.recorrenciaModel.centrosResultados){
            centrosResultadosRecorrencia.push({
                   id: 0,
                    porcentagem: null,
                    centroResultados: {
                        id: this.recorrenciaModel.centrosResultados,                                      
                    },
                    pagar: null,
                    show: true,  
            });
          
          }else{
            //Regras Rateios
            this.recorrenciaModel.regrasRateio.centrosResultados.forEach(centros => {              
              centrosResultadosRecorrencia.push({
                       id: 0,
                      porcentagem: centros.porcentagem,
                      centroResultados: {
                          id: centros.centroResultados.id,                                      
                      },
                      pagar: null,
                      show: true,  
              });              

            });
          }

          this.recorrencias.push({
            id: null,
            data: this.$moment().toDate(),
            dataDeCompetencia: this.$moment().toDate(),
            descricao: this.recorrenciaModel.descricao + ` - ${i}/${totalDeRecorrencias}`,
            funcionarioId: this.usuario.funcionarioId,
            unidadeId: this.recorrenciaModel.unidadeId,
            categoriaFinanceira: {
                id: this.recorrenciaModel.categoriaFinanceira.id,
            },
            contaCorrente: {
                id: this.recorrenciaModel.contaCorrente.id,
            },
            fornecedor: {
                id:  this.recorrenciaModel.fornecedor.id,
            },
            especie: {
              id: this.recorrenciaModel.especie.id,
              nome: this.recorrenciaModel.especie.nome
            },
            notaFiscal: this.recorrenciaModel.notaFiscal,
            observacao: this.recorrenciaModel.observacao,
            pedidoId: 0,
            parcelas: parcela,
            centrosResultados:centrosResultadosRecorrencia
              
            });
        }

        this.isLoading = false;    
      }, 500);
    },
    limparRecorrencia() {
      this.inicializaCamposRecorrencia();
      this.recorrencias = [];      
    },    
    confirmarRecorrencia() {

        this.$buefy.dialog.confirm({
            title: this.$t('SISTEMA.ATENCAO'),
            message:  this.$t('LOTECONTASPAGARCADASTRO.CONFIRMASALVAR'),
            confirmText: this.$t('SISTEMA.CONFIRMAR'),
            cancelText: this.$t('SISTEMA.CANCELAR'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {                  
                this.salvarRecorrencia();                
            }
        });
      return true;
    },  
    async salvarRecorrencia(){

        let campoValidos = true;
        let recorrenciaErro = 0;

        //Passar por cada contas a pagar e validar campos
        this.recorrencias.forEach((r, index) => {

          //Valida campos obrigatorios
          if(!r.descricao || !r.categoriaFinanceira.id || !r.contaCorrente.id || !r.fornecedor.id || !r.especie.id){
            campoValidos =false;

            if(recorrenciaErro == 0 )
              recorrenciaErro = index+1;            
          }  

          //Valida especie de pagamento nas parcelas
          r.parcelas.forEach(parcela => {
            if (parcela.especieDePagamento.id === 0){
              campoValidos =false;
              if(recorrenciaErro == 0 )
                recorrenciaErro = index+1;               
            }                
          });
      
        });


        if(!campoValidos){    

            this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('LOTECONTASPAGARCADASTRO.CAMPOSOBRIGATORIOS',{'0': recorrenciaErro }),
                    type: 'is-warning',
                    hasIcon: true                                          
                });                 
            return true;            
          
        }

        this.isLoading = true;                   
        const recorrencia = this.getItemRecorrencia();                  
        this.$http
          .post(`/api/financeiro/PagarParcelaLoteRecorrencia`, recorrencia)
          .then(() => {
            this.isLoading = false;
            this.$router.push({ name: "financeiro" });
          })
          .catch((err) => {
            this.isLoading = false;
            console.error(err);
            if (err.body?.errors) {
              Object.keys(err.body.errors).forEach((x) => {
                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
              });
            } else {
              const mensagem =
                err.status === 500
                  ? "Foi encontrado um erro no servidor ao salvar o registro"
                  : "Erro ao salvar registro";
              this.$buefy.toast.open({
                duration: 5000,
                message: mensagem,
                type: "is-danger",
                queue: false,
              });
            }
          });      
    
    }
  },
};
</script>
